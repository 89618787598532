import React from "react";
const CTA = () => {
  return (
    <div className="cta">
      <a href="#contact" className="btn changebtn btn-primary" >
        Say Hello
      </a>
    </div>
  );
};

export default CTA;
